<template>
  <div id="dice" class="">

    <div class="mast mb-5x">
      <h1>dice</h1>
    </div>

    <section class="" id="atk">
      <h2 class="my-2">attack origin territory</h2>

      <div class="d-flex flex-row justify-content-center">
        <div class="form-group">
          <label for="atkUnits">units</label>
          <input type="number"
            class="form-control"
            id="atkUnits"
            size="4"
            v-model="units.atk"
            >
        </div>
      </div>
    </section>

    <section class="" id="def">

      <div class="d-flex flex-row justify-content-center">

        <h2>defend territory/s</h2>

        <div class="ms-4" id="teriCtrl">
          <button
            @click="addDefTeri"
            type="button"
            class="btn btn-outline-secondary me-2 add">
            +
          </button>
          <button
            @click="removeDefTeri"
            type="button"
            class="btn btn-outline-secondary remove">
            &mdash;
          </button>
        </div>
      </div>

      <defender
        v-for="def in defenders"
        :deef="def"
        :key="def.uid"
        :uid="def.uid"
        :units="def.units"
        :sides="def.sides"
        @delta="updateDefense"
      ></defender>
    </section>
    <pre class="d-none">{{ defenders }}</pre>

    <section class="admin tite" id="ctrl">

      <h2 class="mb-2">
        simulation
      </h2>

      <div class="d-flex flex-row justify-content-center">
        <div class="mt-4 ms-3">
          <button
            type="button"
            class="btn btn-primary"
            @click="run"
            >
            run
          </button>
        </div>
        <div class="form-group ms-3 me-4">
          <label for="runs">times</label>
          <input type="number"
            class="form-control"
            id="runs"
            size="3"
            v-model="runs"
            >
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center mt-4">
        <div class="mt-4">
          <button
            type="button"
            class="btn btn-secondary"
            @click="reset"
            >
            reset
          </button>
        </div>
        <div class="ms-4">
          <div>abandon</div>
          <button
            @click="toggleAbandon"
            type="button"
            v-bind:class="[{ 'btn-light': !abandon }, { 'btn-secondary': abandon }, 'btn']"
            >
            {{ abandonLabel }}
          </button>
        </div>
      </div>
    </section>

    <section class="admin tite" id="outcome">
      <h2 class="mb-3">outcomes</h2>

      <div>simulations
        <pre>{{ results.analysis }}</pre>
      </div>

      <div class="d-flex flex-row justify-content-center mt-3">

        <div class="me-2">
          invasions
          <pre>{{ results.log.invasion.join('\n') }}</pre>
        </div>

        <div>
          most recent simulation battles
          <pre>{{ results.log.battle.join('\n') }}</pre>
        </div>

      </div>
    </section>

  </div>
</template>

<style lang='scss'>
  #dice {

    section {
      border-top: 1px solid #ccc;
      margin: 1rem 0;
      padding: 1rem 0;
    }

    #atk {}

    #def {
      #teriCtrl {

        .btn.btn-outline-secondary {
          border-radius: 25px;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          &.add {
            padding-left: 0.6rem;
            padding-right: 0.6rem;
          }
          &.remove {
            padding-left: 0.4rem;
            padding-right: 0.4rem;
          }
        }
      }
      .defender {}
    }

    #ctrl {
      //h2 { margin-bottom: 0rem; }
    }

    #outcome {}
  }

  pre {
    border: 1px dashed #aaa;
    background-color: #eaeaea;
    padding: 0.7rem;
  }
</style>

<script>
import { defineAsyncComponent } from 'vue'
import { eventService } from "@/service/event"


const resetResults = function() {
  return {
    log: {
      battle: [],
      invasion: []
    },
    scores: [],
    mean: null,
    win: 0,
    loss: 0,
    analysis: null
  }
}

const defenderDefault = function() {
  return {
    uid: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
    units: Math.floor(Math.random() * (99 - 1) ) + 1,
    sides: { atk: 6, def: 6 },
    leave: 1
  }
}

export default {

  data () {
    return {
      nom: '@util/dice',
      eventService: eventService,
      error: null,
      debug: 0,

      units: {
        atk: roll(3, 99)
        //def: 6
      },

      // allow abandon territory?
      abandon: false,

      invasion: {},

      defenders: [],
      //defenderCount: 1,

      runs: 1,// how many times to simulate
      results: resetResults()
    }
  },

  computed: {
    minRemain() { return (this.abandon)? 0: 1 },
    abandonLabel() { return (this.abandon)? 'on': 'off' }
  },

  components: {
    //defender: () => import('./defender'),
    defender: defineAsyncComponent(() => import('./defender'))
  },

  created() {
    //document.title = this.title
  },

  mounted() {
    if (this.debug) console.debug('%s.mounted()', this.nom)

    eventService.log(['dice','load'])

    this.addDefTeri()
  },

  methods: {

    reset() {
      if (this.debug) {
        console.debug('%s.reset()', this.nom)
      }
      //this.results = resetResults()
      Object.assign(this.$data.results, resetResults())
    },

    run() {
      if (this.debug) {
        console.debug('%s.run(%d)', this.nom, this.runs)
      }

      eventService.log(['dice','runSim'], parseInt(this.runs))

      for (let i=0; i<this.runs; i++){
        this.simulate()
      }
    },

    simulate() {

      // clear logs
      console.clear()
      this.results.log.battle = []
      //this.results.log.invasion = []

      if (this.debug) {
        console.debug('%s.simulate()', this.nom)
        //console.debug(this.$refs)//[0].$refs.anAccount
      }

      let invasionScore = null// score returned by invasion
      const leaveBehind = 1

      if (this.debug) {
        console.debug('the defenders:\n%s', JSON.stringify(this.defenders))
      }

      for (let i=0; i<this.defenders.length; i++){

        if (this.debug) {
          console.debug('prepare invasion %d of %d', i+1, this.defenders.length)
        }
        //const deef = this.$refs.deef[i]
        const deef = this.defenders[i]
        //dummyit
        //const deef = { units: 36, sides: { atk:5, def:6 } }

        this.invasion = {
          atk: {
            units: this.units.atk,
            sides: deef.sides.atk
          },
          def: {
            units: deef.units,
            sides: deef.sides.def,
            instance: i
          }
        }

        if (invasionScore) {// adjust attacking units by results from previous invasion
          this.invasion.atk.units = invasionScore - leaveBehind
        }

        invasionScore = this.invade()
        this.logInvasionResult(invasionScore, i)

        if (invasionScore<2) {// sim is over
          this.analyzeSimulation(invasionScore, 'ran out of attackers')
          return
        }
      }

      this.analyzeSimulation(invasionScore, 'no more defenders')
    },

    invade() {
      //console.clear()

      if (this.debug) {
        console.debug('%s.invade(%s)', this.nom, JSON.stringify(this.invasion))
      }

      this.logInvasionInit()

      while (this.invasion.atk.units>1 && this.invasion.def.units>0) {
        this.battle()
      }

      // cant do another battle, determine why
      let score = this.invasion.atk.units
      if (this.invasion.atk.units < 2) score = this.invasion.def.units * -1
      return score
    },

    logInvasionInit() {
      let line = `invasion ${(this.invasion.def.instance+1)}/${this.defenders.length}`

      this.results.log.invasion.push(line)
    },

    logInvasionResult(score, n) {
      const delimiter = ' .  .  .'

      if (this.debug) {
        console.debug('%s.logInvasionResult(%d | %d)\n  %s', this.nom, score, n, JSON.stringify(this.invasion))
      }

      //let spacer = this.invasion.atk.units.toString().length-1 + plan.def.units.toString().length-1
      let spacer = 3
      let buffer = 0, baseWidth = null

      let line = this.invasion.atk.units+' » '+this.invasion.def.units

      if (baseWidth) {
        buffer = baseWidth - line.length
      } else {
        baseWidth = line.length
      }
      console.debug('base: %d, buff: %d', baseWidth, buffer)
      console.debug( spacer+buffer )

      for (let i=0; i<(spacer+buffer); i++) line+=' '

      line+= '('+score+')'
      this.results.log.invasion.push(line)
      
      if (n+1==this.defenders.length) {//demarcate simulations
        this.results.log.invasion.push(delimiter)
      }

      //'invasion '+n+' result:\n'
    },

    analyzeSimulation(score, reason) {

      if (this.debug) {
        console.debug('%s.analyzeSimulation(%d (%s))', this.nom, score, reason)
        console.debug( this.invasion )
        console.debug( JSON.stringify(this.invasion) )
      }

      // tally score
      /**/
      this.results.scores.push(score)
      this.results.mean = average(this.results.scores)
      if (score>0) this.results.win++
      else this.results.loss++

      this.results.analysis = this.results.scores.length+' simulations\n'
      this.results.analysis+= this.results.mean.toFixed(3)+' average score\n'
      this.results.analysis+= this.results.win+'-'+this.results.loss+' record\n'
      this.results.analysis+= (this.results.win/this.results.scores.length).toFixed(3) +' win percentage'
      /**/
    },

    battle() {
      if (this.debug) {
        //console.debug('%s.battle(%d >> %d)', this.nom, this.invasion.atk.units, this.invasion.def.units)
      }

      let line = `(${this.invasion.atk.units} >> ${this.invasion.def.units}): `

      /*let max
      if (this.invasion.atk.units>2) max = 3
      else max = this.invasion.atk.units/**/

      let atk = []
      let max = 3
      if (this.invasion.atk.units < max) {
        max = this.invasion.atk.units - this.minRemain
      }
      for (let i=0; i<max; i++) {
        atk.push(roll(1, this.invasion.atk.sides))
        atk.sort(compare)
      }

      /*let max
      if (this.invasion.def.units>1) max = 2
      else max = this.invasion.def.units*/

      let def = []
      max = 2
      if (this.invasion.def.units < max) max = this.invasion.def.units//always 1 innit?
      for (let i=0; i<max; i++) {
        def.push(roll(1, this.invasion.def.sides))
        def.sort(compare)
      }

      if (this.debug) {
        //console.debug('%s vs %s', JSON.stringify(atk), JSON.stringify(def))
      }

      line+= `${JSON.stringify(atk)} vs ${JSON.stringify(def)}`
      this.results.log.battle.push(line)

      for (let i=0; i<def.length; i++) {
        if (def[i] >= atk[i]) this.invasion.atk.units--
        else this.invasion.def.units--
      }
    },

    // param update: data object containing defender id & updated data
    updateDefense(update) {

      if (this.debug) console.debug('%s.updateDefense(%s)', this.nom, JSON.stringify(update))

      let delta = this.defenders.find(d => d.uid === update.uid)
      Object.assign(delta, update)
    },

    toggleAbandon() {
      //this.abandon = !this.abandon
      this.abandon^= true
    },

    // add & remove a defense territory
    addDefTeri() {
      if (this.debug) {
        console.debug('%s.addDefTeri()', this.nom)
      }
      this.defenders.push(defenderDefault())
    },

    removeDefTeri() {
      if (this.debug) {
        console.debug('%s.removeDefTeri()', this.nom)
      }
      if (this.defenders.length>1) {
        this.defenders.pop()
      }
    }

  }
}

const roll = function(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min
}

function compare(a, b) {
  if (a<b) { return 1 }
  if (a>=b) { return -1 }
  //return 0
}

const average = list => list.reduce((prev, curr) => prev + curr) / list.length

</script>
